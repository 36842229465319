import { observable, action } from 'mobx'
import { Model, Store, Casts } from 'store/Base'
import { OperatorCapabilityStore } from './OperatorCapability'
import { PUBLIC_URL } from 'helpers'
import { User } from './User'
import { LeaveCalendarStore } from './LeaveCalendar'
import { LeaveSlotStore } from './LeaveSlot'
import { WorkScheduleStore } from './WorkSchedule'
import { ExtraLeaveBalanceStore } from './ExtraLeaveBalance'
import { OperatorAssignmentStore } from './OperatorAssignment'
import { OperatorAllocationStore } from './OperatorAllocation'
import { WorkTimeStore } from './WorkTime'
import { ExtraTimeBalanceStore } from './ExtraTimeBalance'

export class Operator extends Model {
  static backendResourceName = 'operator'
  static omitFields = ['leaveBalance', 'clockInTime', 'fullName']

  @observable id = null
  @observable email = null
  @observable firstName = ''
  @observable lastName = ''
  @observable avatar = null
  @observable createdAt = null
  @observable badgeId = ''
  @observable leaveBalance = {}
  @observable clockInTime = null
  @observable fullName = ''
  @observable deleted = false
  @observable clockInRoundOff = null
  @observable clockOutRoundOff = null
  @observable showHourSummaryInCapacity = true

  @action setInput(name, value) {
    const res = super.setInput(name, value)
    if (['firstName', 'lastName'].includes(name)) {
      this.fullName = `${this.firstName} ${this.lastName}`
    }
    return res
  }

  getUrl() {
    return `/personel/operator/${this.id}/edit`
  }

  relations() {
    return {
      manager: User,
      user: User,
      operatorCapabilities: OperatorCapabilityStore,
      leaveCalendars: LeaveCalendarStore,
      leaveSlots: LeaveSlotStore,
      workSchedules: WorkScheduleStore,
      extraLeaveBalances: ExtraLeaveBalanceStore,
      assignments: OperatorAssignmentStore,
      allocations: OperatorAllocationStore,
      workTimes: WorkTimeStore,
      extraTimeBalances: ExtraTimeBalanceStore,
    }
  }

  casts() {
    return {
      avatar: {
        parse(attr, value) {
          if (value) {
            return PUBLIC_URL + value
          }

          return null
        },
        toJS(attr, value) {
          return value
        },
      },
      createdAt: Casts.datetime,
      clockInTime: Casts.datetime,
    }
  }
}

export class OperatorStore extends Store {
  Model = Operator
  static backendResourceName = 'operator'

  start(badgeId) {
    return this.wrapPendingRequestCount(this.api.post(`${this.url()}start/`, { badge_id: badgeId }))
  }
}
